import React from "react";
import Home from "./Home";
import Privacy from "./Privacy";
import TermsAndConditions from "./TermsAndConditions";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/terms" element={<TermsAndConditions />}></Route>
        <Route path="/privacy" element={<Privacy />}></Route>
        <Route path="*" element={<Home />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
