import React from "react";
import Gdpr from "./components/Gdpr.tsx";
import Welcome from "./components/Welcome.tsx";
import Footer from "./components/Footer.tsx";

function Privacy() {
  return (
    <React.Fragment>
      <Welcome />
      <Gdpr />
      <Footer />
    </React.Fragment>
  );
}

export default Privacy;
