import * as React from "react";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goToShop = () => {
    window.open("https://www.shopterragusta.be", "_blank");
  };
  const goToTerms = () => {
    navigate("/terms");
  };
  const goToPrivacy = () => {
    navigate("/privacy");
  };
  const goToHome = () => {
    navigate("/");
  };
  const goToInsta = () => {
    window.open("https://www.instagram.com/terragusta_be/", "_blank");
  };
  const goToFb = () => {
    window.open(
      "https://www.facebook.com/profile.php?id=61551753844407",
      "_blank"
    );
  };

  return (
    <Wrapper>
      <Wrapper2>
        <Container>
          <Logo src="assets/images/logo.png" onClick={goToHome} />
          <Column>
            <ColumnTitle>over terragusta</ColumnTitle>
            <ColumnItem onClick={goToShop}>Shop</ColumnItem>
            <ColumnItem onClick={goToTerms}>Algemene voorwaarden</ColumnItem>
            <ColumnItem onClick={goToPrivacy}>Privacy</ColumnItem>
          </Column>
          <Column>
            <ColumnTitle>follow us</ColumnTitle>
            <Column2Wrapper>
              <SocialMedia src="assets/images/insta.png" onClick={goToInsta} />
              <SocialMedia src="assets/images/fb.png" onClick={goToFb} />
            </Column2Wrapper>
          </Column>
        </Container>
      </Wrapper2>
      <Credits>©2023 by Terragusta. Created by Jonas Hofmans</Credits>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.div`
  width: 100%;
  left: 0;
  margin-left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #f3f2e6;
`;

const Wrapper2 = styled.div`
  width: 100%;
  left: 0;
  margin-left: 0;

  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  width: 900px;

  display: flex;
  justify-content: space-between;

  padding-top: 50px;
  padding-bottom: 10px;

  @media only screen and (max-width: 935px) {
    width: 750px;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 30px;
  }
`;

const Logo = styled.img`
  width: 124px;
  height: 124px;
  cursor: pointer;

  margin-top: -10px;

  @media only screen and (max-width: 768px) {
    width: 90px;
    height: 90px;
  }
`;

const Column = styled.div`
  width: 190px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-left: 10px;
  }
`;

const ColumnTitle = styled.p`
  font-family: Operetta;
  font-size: 24px;
  line-height: 24px;

  color: #205b41;

  text-align: left;

  width: 190px;
  border-bottom: 1px solid #205b41;

  margin-bottom: 7px;

  @media only screen and (max-width: 768px) {
    width: 80%;
    margin-top: 20px;
  }
`;

const ColumnItem = styled.b`
  font-family: Permian;
  font-size: 13px;

  color: #205b41;

  text-align: left;

  cursor: pointer;
`;

const Column2Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const SocialMedia = styled.img`
  width: 25px;
  height: 25px;

  margin-right: 5px;

  cursor: pointer;
`;

const Credits = styled.b`
  font-family: Permian;
  font-size: 13px;

  color: #205b41;
`;
