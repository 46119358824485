import * as React from "react";
import { styled } from "styled-components";

const Description = () => {
  const goToShop = () => {
    window.open("https://www.shopterragusta.be", "_blank");
  };

  return (
    <React.Fragment>
      <Container>
        <DescriptionTitle>
          <Oil>olie</Oil>gemaakt
          <br />
          van hennep
        </DescriptionTitle>
        <DescriptionText>
          Onze hennep wordt met enorm veel zorg geteeld. Zonder gebruik te maken
          van kunstmatige of chemische producten. Op goede, vruchtbare
          landbouwgronden in Klein-Brabant.
          <br />
          Goed voor zowel onze omgeving, voor ons als landbouwer als voor jou.
          Want jij bent de laatste en belangrijkste schakel in ons proces.
          Lekker en eerlijk voedsel met vakmanschap geteeld zodat jij voor de
          mensen rondom jou een heerlijke en gezonde maaltijd kan bereiden. Elke
          dag opnieuw. Smakelijk.
          <br />
          <br />
          Onze producten zijn hier online en in volgende winkels te koop:
          <br />
          <Address
            onClick={() =>
              window.open("https://www.kasteeltjehingene.be/", "_blank")
            }
          >
            ’t Kasteeltje in Hingene
          </Address>
          <br />
          <Address
            onClick={() => window.open("http://www.seizoensmaak.be/", "_blank")}
          >
            Seizoensmaak in Londerzeel
          </Address>
          <br />
          <Address
            onClick={() => window.open("http://www.nieuwe-vaart.be/", "_blank")}
          >
            Nieuwe Vaart in Antwerpen
          </Address>
          <br />
          <Address
            onClick={() => window.open("http://www.puurlocal.be/", "_blank")}
          >
            Puur Local in Retie
          </Address>
          <br />
          <Address
            onClick={() =>
              window.open("http://heirbauthoeveproducten.be/", "_blank")
            }
          >
            Heirbaut Hoeveproducten in Temse
          </Address>
          <br />
          …. in jouw winkel?
        </DescriptionText>
        <ShopButton onClick={goToShop}>Webshop</ShopButton>
      </Container>
      <Leaf alt="" src="assets/images/leaf.jpg" />
      <Bottle alt="" src="assets/images/bottle.png" />
    </React.Fragment>
  );
};

export default Description;

const Address = styled.span`
  cursor: pointer;
`;

const DescriptionText = styled.p`
  font-family: Permian;
  font-size: 16px;

  color: #205b41;

  margin-top: 10px;
  margin-left: 380px;
  width: 572px;

  @media only screen and (max-width: 935px) {
    margin-left: 250px;
    width: 500px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 14px;
    margin-left: 0px;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0px;
    text-align: center;
  }
`;

const DescriptionTitle = styled.p`
  font-family: Operetta;
  font-size: 40px;
  line-height: 40px;

  color: #205b41;

  margin-top: 50px;
  margin-right: 65px;

  @media only screen and (max-width: 935px) {
    font-size: 35px;
    line-height: 40px;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 530px;
    font-size: 23px;
    line-height: 23px;
    margin-right: 0px;
  }
`;

const Oil = styled.span`
  font-family: Operetta;
  font-size: 130px;
  line-height: 130px;

  color: #205b41;

  @media only screen and (max-width: 935px) {
    font-size: 100px;
    line-height: 100px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 50px;
    line-height: 50px;
  }
`;

const Leaf = styled.img`
  width: 300px;
  height: 300px;

  transform: rotate(150deg);

  position: absolute;
  top: 680px;
  left: 1500px;
`;

const Bottle = styled.img`
  width: 405px;

  position: absolute;
  top: 320px;

  @media only screen and (min-width: 1390px) {
    right: 1080px;
  }

  @media only screen and (max-width: 1390px) {
    left: -100px;
  }

  @media only screen and (max-width: 935px) {
    left: -90px;
    width: 355px;
  }

  @media only screen and (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;

    top: 170px;

    width: 280px;
    height: 630px;
  }
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ShopButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #205b41;

  &:hover {
    background-color: #6ea089;
  }

  border: 1px solid #205b41;

  font-family: Permian;
  color: white;
  font-size: 24px;

  cursor: pointer;

  width: 200px;
  height: 60px;

  margin-bottom: 50px;
  margin-top: 20px;
`;
