import * as React from "react";
import { styled } from "styled-components";

const Gdpr = () => {
  return (
    <Wrapper>
      <Container>
        <Title>
          (NL) Vertrouwelijkheid van de gegevens en privacycharter van Lab Agro
        </Title>
        <br />
        <br />
        <Text>
          Terragusta is een beschermde merknaam van Lab Agro GCV. Elke aankoop
          op de website www.terragusta.be of www.shopterragusta.be is onderhavig
          aan volgende vertrouwelijkheidsclausules en privacycharter als u van
          de dienst van Lab Agro GCV gebruik maakt.
          <br /> Wij hebben dit charter opgesteld omdat we je bij LAB AGRO heel
          duidelijk willen informeren over de manier waarop wij jouw gegevens
          gebruiken binnen de organisatie. In dit charter lees je in detail
          welke stappen wij ondernemen om je privacy te vrijwaren en hoe je op
          elk moment kan tussenkomen. Dit charter is van toepassing op alle
          communicatiemiddelen van LAB AGRO (mail, telefoon, GSM, e-mail,
          whatsapp,...).
          <br />
          <br />
          Welkom op www.terragusta.be of www.shopterragusta.be
          <br /> Uw privacy is voor ons een belangrijk onderwerp. In deze
          privacyverklaring kunt u kennis nemen welke persoonsgegevens wij
          verwerken en hoe wij hier mee om gaan.
          <br /> Hieronder een overzicht van de persoonsgegevens die wij
          verwerken:
          <br />● Voor- en achternaam, naam van uw bedrijf
          <br />● E-mailadres <br />● Telefoonnummer <br />● Adresgegevens{" "}
          <br />● Facturatiegegevens <br />● BTW-nummer <br />●
          Bankrekeningnummer <br />● Gerichte of sfeerfoto's voor publicatie op
          sociale media en PR activiteiten <br />● Vertrouwelijkheid
          <br />
          <br />
          LAB AGRO erkent de zorg van de websitebezoekers i.v.m. de
          vertrouwelijkheid van hun gegevens.
          <br /> Wij behandelen je persoonlijke gegevens met de grootste
          vertrouwelijkheid en met respect voor de regels van GDPR. Wij
          verzamelen persoonlijke informatie slechts voor specifieke en
          wettelijke doeleinden.
          <br />
          <br />
          <SubTitle>GENERAL DATA PROTECTION REGULATION (GDPR)</SubTitle>
          <br />
          <b>Waarom we gegevens nodig hebben:</b>
          <br />
          LAB AGRO verwerkt uw persoonsgegevens, voor de volgende doelen: <br />
          ● Het afhandelen van uw betaling <br />● Verzenden van onze
          nieuwsbrief en/of reclamefolder <br />● U te kunnen bellen indien dit
          nodig is om onze dienstverlening uit te kunnen voeren <br />● U te
          informeren over wijzigingen van onze diensten en producten <br />● U
          de mogelijkheid te bieden een account aan te maken <br />● Om goederen
          en diensten bij u af te leveren <br />● LAB AGRO analyseert uw gedrag
          op de website om daarmee onze website te verbeteren en ons aanbod van
          producten en diensten af te stemmen op uw voorkeuren. <br />● LAB AGRO
          verwerkt ook persoonsgegevens als wij hier wettelijk toe verplicht
          zijn, zoals bijvoorbeeld gegevens die wij nodig hebben voor onze
          belastingaangifte.
          <br />
          <br />
          <b>Hoe lang we gegevens bewaren</b>
          <br />
          LAB AGRO zal uw persoonsgegevens niet langer bewaren dan strikt nodig
          is om de doelen te realiseren waarvoor uw gegevens worden verzameld.
          <br />
          <br />
          <b>Gegevens inzien, aanpassen of verwijderen</b>
          <br /> U hebt recht op volgende bescherming: <br />
          Wilt u toegang, aanpassing, update, of verwijdering van je
          persoonlijke informatie, kan u op elk moment mailen naar
          friedvanopstal@terragusta.be
          <br /> Wij gebruiken uw naam, adres en telefoongegevens louter voor
          onze leveringen. De servers waarop deze gegevens zijn opgeslagen
          garanderen voldoende veiligheid en hanteren de strengste regels
          volgens de GDPR. <br />U kan op elk moment uitschrijven van onze
          marketingcommunicaties. Klik op "unsubscribe" of "uitschrijven", een
          link onderaan elke marketing e-mail die je krijgt. Zit u nog met
          vragen, neem contact op via friedvanopstal@terragusta.be <br />
          Zelfs al hebben we, met uw toestemming, uw gegevens verwerkt, staat
          het u natuurlijk vrij om op elk moment deze toestemming te weerleggen,
          zonder dat dit enige afbreuk doet aan uw rechten. <br />U hebt het
          recht om een klacht in te dienen bij een
          gegevensbeschermingsautoriteit in verband met het verkrijgen en
          gebruik van uw persoonlijke data.
          <br />
          <br />
          <b>Gebruik</b>
          <br />
          Wij verzamelen uw e-mailadres om u te informeren over onze
          activiteiten. In elke nieuwsbrief vindt u de mogelijkheid om u uit te
          schrijven of uw gegevens aan te passen.
          <br />
          <br />
          <b>Engagement - Delen met anderen</b>
          <br /> Wij communiceren uw vertrouwelijke gegevens enkel aan derden
          die deze informatie nodig zouden hebben om een welbepaalde missie uit
          te voeren die wij hen expliciet aangevraagd hebben. (bv mailing via
          mailchimp, bv. levering via koerierdienst,...) Deze derden mogen uw
          informatie enkel gebruiken in het kader van die specifieke
          activiteiten en zullen ze geenszins elders mogen gebruiken. Deze derde
          zal nooit toegang krijgen tot persoonlijke gegevens, aankoopinformatie
          of navigatie-informatie. Met bedrijven die uw gegevens verwerken in
          onze opdracht, sluiten wij een bewerkersovereenkomst om te zorgen voor
          eenzelfde niveau van beveiliging en vertrouwelijkheid van uw gegevens.
          LAB AGRO blijft verantwoordelijk voor deze verwerkingen. <br />
          Wij hebben bewerkersovereenkomsten afgesloten met: <br />● Odoo
          (platform en betaalsysteem) <br />
          LAB AGRO zullen uw persoonlijke gegevens nooit verkopen of huren aan
          derden voor commerciële doeleinden. Wij behandelen uw gegevens als
          vertrouwelijke informatie.
          <br />
          <br />
          <b>Functionele cookies</b>
          <br />
          Wij gebruiken cookies om uw ervaring op onze website te verbeteren.
          Een cookie is een informatie die onze website plaatst op de harde
          schijf van uw computer om uw surfgedrag op onze website te volgen. Een
          cookie laat ook toe uw IP-adres te herkennen als u onze site opnieuw
          bezoekt. Cookies worden bewaard om onze website te verbeteren en zo
          aangenaam mogelijk te maken. Ze worden vooral gebruikt voor
          commerciële doeleinden, maar ook om aan te duiden vanuit welk land je
          surft en bv de juiste taal meteen voor te stellen. Informatie over hoe
          cookies verwijderen of controleren vindt u hier :
          www.aboutcookies.org. Wel even opletten dat, als u de cookies
          deactiveert op uw computer, uw surfplezier op onze website wel eens
          wat minder zou kunnen zijn.
          <br />
          <br />
          <b>Tracking cookies</b>
          <br /> Tracking cookies volgen uw bezoek aan onze website om daarmee
          een profiel van websitebezoekers op te bouwen. Ze kunnen op basis
          daarvan de website optimaliseren of op jou afgestemde advertenties
          tonen. Wij gebruiken tracking cookies van Facebook
          ‘(https://www.facebook.com/policies/cookies/) en Google
          (https://policies.google.com/privacy). Als u hiermee niet akkoord
          bent, krijgt u bij uw eerste bezoek aan de site de mogelijkheid om dit
          te weigeren.
          <br />
          <br />
          <b>Uw rechten</b>
          <br />U hebt recht tot toegang tot uw persoonlijke gegevens, uw
          gegevens te allen tijde te verbeteren of te verwijderen, u uit onze
          mailinglijst uit te schrijven of u uit te schrijven uit onze website
          (niet leuk, maar kom, no hard feelings). Stuur hiervoor gewoon een
          mailtje naar friedvanopstal@terragusta.be. Bij elke mail die u
          ontvangt, heeft u de mogelijkheid om zich uit te schrijven. Een
          bevestigingsmail zal u worden verstuurd.
          <br />
          <br />
          <b>Beveiliging</b>
          <br /> LAB AGRO neemt de bescherming van uw gegevens serieus en neemt
          passende maatregelen om misbruik, verlies, onbevoegde toegang,
          ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan.
          Als u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er
          aanwijzingen zijn van misbruik, neem dan contact op met onze directie
          of via friedvanopstal@terragusta.be. De GDPR verantwoordelijke is
          Fried Van Opstal. Hij zal zo snel mogelijk ingrijpen in geval van
          datalekken.
          <br />
          <br />
          <b>Aanpassing</b>
          <br /> LAB AGRO behoudt zich het recht voor om deze privacy policy te
          wijzigen. Via de website wordt u hiervan op de hoogte gebracht. Dank
          voor uw vertrouwen in LAB AGRO!
        </Text>
      </Container>
    </Wrapper>
  );
};

export default Gdpr;

const Wrapper = styled.div`
  width: 100%;
  left: 0;
  margin-left: 0;

  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  width: 100%;
  background-position: 50% 55%;
  background-size: cover;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url("assets/images/paper.jpg");

  padding-top: 75px;
  padding-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;

  @media only screen and (max-width: 768px) {
    padding-top: 50px;

    padding-left: 10px;
    padding-right: 10px;
  }
`;

const Title = styled.b`
  font-size: 32px;
  font-family: Permian;
  color: #205b41;

  @media only screen and (max-width: 935px) {
    font-size: 26px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

const SubTitle = styled.b`
  font-size: 24px;
  font-family: Permian;
  color: #205b41;

  @media only screen and (max-width: 935px) {
    font-size: 20px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const Text = styled.p`
  font-size: 16px;
  font-family: Permian;
  color: #205b41;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
